<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-certificate</v-icon>
      </v-btn>
      <v-toolbar-title>Portfolios</v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'PortfolioList' }"> Übersicht </v-tab>
          <v-tab :to="{ name: 'PortfolioCreation' }"> Erstellen </v-tab>
          <v-tab :to="{ name: 'PortfolioCourses' }"> Kurse </v-tab>
          <v-tab :to="{ name: 'PortfolioPlacements' }"> Stage </v-tab>
          <v-tab :to="{ name: 'PortfolioTypeList' }"> Vorlagen </v-tab>
          <v-tab :to="{ name: 'PortfolioPrint' }"> Druck </v-tab>
          <v-tab :to="{ name: 'PortfolioTasks' }"> Tasks </v-tab>
          <v-tab :to="{ name: 'PortfolioArchive' }"> Archiv </v-tab>
        </v-tabs>
        <SchoolYearTabs v-if="showSchoolYearTabs" right v-model="schoolYear" />
        <TermTabs v-if="showTermTabs" right v-model="term" />
      </template>
    </v-app-bar>
    <router-view :search="search" :schoolYear="schoolYear" :term="term" />
  </v-container>
</template>

<script>
import SchoolYearTabs from '@/components/SchoolYearTabs';
import TermTabs from '@/components/TermTabs';

export default {
  name: 'Portfolio',
  components: {
    SchoolYearTabs,
    TermTabs,
  },
  data() {
    return {
      search: '',
      schoolYear: null,
      term: null,
    };
  },
  computed: {
    showSchoolYearTabs() {
      return this.$route.name == 'PortfolioPlacements';
    },
    showTermTabs() {
      return (
        this.$route.name == 'PortfolioCourses' ||
        this.$route.name == 'PortfolioCreation'
      );
    },
  },
};
</script>